import React, { useState, useEffect } from 'react'
import { Table, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from '../../components/inputs/button'
import { ConfirmDialog } from '../modals/ConfirmDialog'
import { del, get } from '../../services/http/httpMethods'
import { showErrorToast, showSuccessToast } from '../../utils/toastUtil'
import { Loader } from '../../components/loader'

export type Props = {
    isOpen: boolean
    title: string
    subTitle: string
    id: string
}

const ReportsTable = () => {
    const [reports, setReports] = useState<any[]>([])
    const [confirmDialog, setConfirmDialog] = useState<Props>({
        isOpen: false,
        title: '',
        subTitle: '',
        id: '',
    })
    const [loading, setLoading] = useState(false)

    const getReports = async () => {
        setLoading(true)
        await get(`/api/report`)
            .then((response) => {
                setLoading(false)
                setReports(response.data)
            })
            .catch((error) => showErrorToast(error.message))
        setLoading(false)
    }

    useEffect(() => {
        getReports()
    }, [])

    const DeleteReport = async (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        })

        await del(`/api/report/${id}`)
            .then((response) => {
                setReports(response.data)
                showSuccessToast('Report details deleted successfully')
            })
            .catch((error) => showErrorToast(error.message))
        getReports()
    }

    return (
        <>
            <Col lg={{ span: 12, offset: 0 }}>
                <div className="mt-4">
                    <h3 className="mt-4 d-inline"> Reports </h3>
                    <Link to="/reports/new">
                        <Button
                            type="button"
                            text="Add New"
                            className="btn btn-outline-primary float-right d-inline"
                        />
                    </Link>
                </div>
            </Col>
            <Col className="mt-4" lg={{ span: 12, offset: 0 }}>
                <Card className="custom-card">
                    <Card.Body className="custom-card-body">
                        <Table striped bordered hover className="w-100">
                            <thead>
                                <tr>
                                    <th> Name </th>
                                    <th> Start Date </th>
                                    <th> Schedule </th>
                                    <th> Delivery </th>
                                    <th> Last Occurrence </th>
                                    <th> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && (
                                    <tr>
                                        <td colSpan={6} className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                )}
                                {!loading && reports && !reports.length && (
                                    <tr>
                                        <td colSpan={6} className="text-center">
                                            {' '}
                                            No data available{' '}
                                        </td>
                                    </tr>
                                )}
                                {!loading &&
                                    reports &&
                                    reports.map((report) => (
                                        <tr key={report._id}>
                                            <td> {report.name} </td>
                                            <td>
                                                {' '}
                                                {report.startDate.substr(0, 10)}{' '}
                                            </td>
                                            <td> {report.schedule.frequency} </td>
                                            <td>
                                                {' '}
                                                {report.delivery &&
                                                report.delivery.dm.length
                                                    ? report.delivery.dm.map(
                                                          (v) => `${v.name} `
                                                      )
                                                    : report.delivery &&
                                                      report.delivery.channel.length
                                                    ? report.delivery.channel.map(
                                                          (v) => `${v.name} `
                                                      )
                                                    : report.delivery &&
                                                      report.delivery.email.length
                                                    ? report.delivery.email.map(
                                                          (v) => `${v}`
                                                      )
                                                    : ''}
                                            </td>
                                            <td>
                                                {' '}
                                                {report.schedule &&
                                                report.schedule.frequency ===
                                                    'EveryWeek'
                                                    ? report.schedule.days.map(
                                                          (v) => `${v}, `
                                                      )
                                                    : 'Manual'}
                                            </td>
                                            <td>
                                                <span
                                                    style={{
                                                        paddingRight: 0.5 + 'em',
                                                    }}
                                                >
                                                    <Link
                                                        to={`/reports/${report._id}`}
                                                    >
                                                        <i className="bx bxs-edit-alt" />
                                                    </Link>
                                                </span>
                                                <span style={{ cursor: 'pointer' }}>
                                                    <i
                                                        className="bx bxs-trash-alt"
                                                        onClick={() =>
                                                            setConfirmDialog({
                                                                isOpen: true,
                                                                title: 'Are you sure to delete this record',
                                                                subTitle:
                                                                    "You can't undo this operation",
                                                                id: report._id,
                                                            })
                                                        }
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    confirmDelete={DeleteReport}
                />
            </Col>
        </>
    )
}

export default ReportsTable
