import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../../components/inputs/button'

export type ConfirmDialogProps = {
    confirmDialog: any
    setConfirmDialog: any
    confirmDelete: any
}

export const ConfirmDialog = ({
    confirmDialog = {},
    setConfirmDialog,
    confirmDelete,
}: ConfirmDialogProps) => {
    return (
        <>
            <Modal
                show={confirmDialog.isOpen}
                onHide={() =>
                    setConfirmDialog({
                        ...confirmDialog,
                        isOpen: false,
                    })
                }
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {confirmDialog.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{confirmDialog.subTitle}</Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-secondary"
                        text="No"
                        type="button"
                        onClick={() =>
                            setConfirmDialog({
                                ...confirmDialog,
                                isOpen: false,
                            })
                        }
                    />
                    <Button
                        className="btn btn-danger"
                        text="Yes"
                        type="button"
                        onClick={() => confirmDelete(confirmDialog.id)}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
