import React from 'react'
import Routes from '../../routes/routes'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { paths, routes } from '../../routes/routes.config'
import history from '../../routes/history'
import AuthLayout from '../../layouts/Auth/AuthLayout'
import AppLayout from '../../layouts/App/AppLayout'
import { authenticationService } from '../../services/auth.service'
import PageNotFound from '../../pages/auth/page-not-found/page-not-found'

export type AppNavigatorProps = any

export const AppNavigator = (props: AppNavigatorProps) => {
    return (
        <Router history={history}>
            <Switch>
                <Redirect
                    key="default"
                    exact
                    from="/"
                    to={paths.defaultPostLogin}
                ></Redirect>
                {routes.map((item, index) => {
                    if (item.path.includes('auth/')) {
                        if (authenticationService.currentUserValue && authenticationService.currentUserValue.isBotAddedToSlack) {
                            return (
                                <Redirect
                                    exact
                                    key={index}
                                    from={item.path}
                                    to={paths.defaultPostLogin}
                                ></Redirect>
                            )
                        } else {
                            return (
                                <Route
                                    exact
                                    path={item.path}
                                    key={index}
                                    component={item.component}
                                >
                                    <AuthLayout key={index} />
                                </Route>
                            )
                        }
                    } else if (!item.path.includes('auth/') && !authenticationService.currentUserValue) {
                        return (<Redirect
                            exact
                            key={index}
                            from={item.path}
                            to={paths.login}
                        ></Redirect>)
                    } else {
                        return (
                            <Routes
                                exact
                                path={item.path}
                                key={index}
                                component={item.component}
                            >
                                <AppLayout key={index} path={item.path} />{' '}
                            </Routes>
                        )
                    }
                })}
                <Route component={PageNotFound} />
            </Switch>
        </Router>
    )
}
