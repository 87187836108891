import React from 'react'
import classNames from 'classnames'
import './Button.scss'

export type ButtonProps = {
    /**
     * Type of button [submit/button].
     */
    type: 'submit' | 'button'
    /**
     * Is Button disabled?
     */
    disabled?: false | true
    /**
     * Class name
     */
    className: string
    /**
     * Is button processing?
     */
    isLoading?: false | true
    /**
     * Text
     */
    text?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
    text = 'Submit',
    disabled,
    className = 'btn btn-primary',
    isLoading,
    type = 'submit',
    ...rest
}: ButtonProps) => {
    return (
        <button
            className={classNames(className)}
            type={type}
            disabled={isLoading}
            {...rest}
        >
            {isLoading ? <i className="bx bx-loader-alt bx-spin"></i> : text}
        </button>
    )
}

Button.defaultProps = {
    text: 'Submit',
    className: 'btn btn-primary',
    isLoading: false,
    type: 'submit',
}
