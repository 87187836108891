/*
 * Timeout for API
 */
export const timeout = Number(process.env.REACT_APP_API_TIMEOUT || 15000)

/*
 * Base URL
 */
export const baseURL = process.env.BASE_URL || 'https://angularminds.app'

/**
 * Redirect URI for sign in with slack
 */
export const redirectURL = 'https://angularminds.app/auth/add-bot-slack'

/**
 * Redirect URI for add slack Bot
 */
 export const redirectURL_ADD_BOT = 'https://angularminds.app/auth/slack-verification'
