import React from 'react'
import { Col } from 'react-bootstrap'
import { Footer } from '../../../components/layout/footer/Footer'
import './page-not-found.scss'

export default function PageNotFound() {
    /*
     * Render
     */
    return (
        <>
            <div className="text-center pt-3">
                <h2>99 Bots</h2>
            </div>
            <div className="container">
                <Col md={12} lg={12}>
                    <div className="text-center">
                        <h2 className="heading">404</h2>
                        <h3>
                            {' '}
                            <b>Oops! page not found</b>
                        </h3>
                        <div className="text-muted mt-3">
                            Sorry, but the page you are looking for is not found.
                            Please make sure you have typed the correct URL
                        </div>
                    </div>
                </Col>
            </div>
            <Footer />
        </>
    )
}
