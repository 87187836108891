import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { authenticationService } from '../../../services/auth.service'
import './Header.scss'
import { Link } from '../../inputs/link'
import ReportsTable from '../../../pages/table/reports-table'
import { paths } from '../../../routes/routes.config'
import Avatar from 'react-avatar'

import './Header.scss'
import { isEmpty } from 'lodash'

export type HeaderProps = any

export const Header = (props: HeaderProps) => {
    const location = useLocation()
    const waterCoolerpaths = ['/water-cooler/messages', '/water-cooler/schedules']

    const reportsPaths = ['/reports', '/occurrences']

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        _id: '',
        avatar: '',
        phone: {
            countryCode: '',
            number: '',
        },
    })

    const Subscription = async () => {
        await authenticationService.currentUser.subscribe((user) => {
            setUser(user)
        })
    }

    useEffect(() => {
        authenticationService.loadCurrentUser()
        Subscription()
    }, [])

    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/water-cooler/schedules">
                        99 Bots
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <li className="nav-item text-nowrap">
                            <Link to="/water-cooler/messages" className="nav-link">
                                {' '}
                                Water Cooler{' '}
                            </Link>
                        </li>
                        <li className="nav-item text-nowrap">
                            <Link
                                to="/reports"
                                className="nav-link"
                                onClick={ReportsTable}
                            >
                                Reports
                            </Link>
                        </li>

                        <li className="nav-item text-nowrap">
                            <Nav>
                                <NavDropdown
                                    alignRight
                                    id="basic-navbar-nav"
                                    className={
                                        paths.userSettings.includes(
                                            location.pathname
                                        )
                                            ? 'active'
                                            : ''
                                    }
                                    title={
                                        isEmpty(user.avatar) ? (
                                            <Avatar
                                                name={`${user.firstName}
                                          ${' '}
                                          ${user.lastName}`}
                                                round
                                                size="30"
                                            />
                                        ) : (
                                            user.avatar && (
                                                <div className="profile-pic">
                                                    <img
                                                        className="user-avatar-header"
                                                        src={user.avatar}
                                                    />
                                                </div>
                                            )
                                        )
                                    }
                                >
                                    <NavDropdown.Item href="/settings/profile">
                                        <i className="bx bx-user d-inline bx-xs mr-2" />
                                        My profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/settings/password">
                                        <i className="bx bx-lock-alt d-inline bx-xs mr-2" />
                                        Change password
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="/settings/company">
                                        <i className="bx bx-customize d-inline bx-xs mr-2" />
                                        Company settings
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item
                                        href="#!"
                                        onClick={authenticationService.localLogout}
                                    >
                                        <i className="bx bx-log-out d-inline bx-xs mr-2"></i>
                                        Logout{' '}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </li>
                    </Nav>
                </Container>
            </Navbar>
            {waterCoolerpaths.includes(location.pathname) ? (
                <Navbar
                    variant="dark"
                    className="p-0"
                    style={{ backgroundColor: '#424950' }}
                >
                    <Container>
                        <Nav className="me-auto">
                            <li className="nav-item text-nowrap ">
                                <Link
                                    to="/water-cooler/messages"
                                    className="nav-link"
                                >
                                    {' '}
                                    Messages{' '}
                                </Link>
                            </li>
                            <li className="nav-item text-nowrap">
                                <Link
                                    to="/water-cooler/schedules"
                                    className="nav-link"
                                >
                                    {' '}
                                    Schedules{' '}
                                </Link>
                            </li>
                        </Nav>
                    </Container>
                </Navbar>
            ) : (
                ''
            )}

            {reportsPaths.includes(location.pathname) ? (
                <Navbar
                    variant="dark"
                    className="p-0"
                    style={{ backgroundColor: '#424950' }}
                >
                    <Container>
                        <Nav className="me-auto">
                            <li className="nav-item text-nowrap ">
                                <Link to="/reports" className="nav-link">
                                    {' '}
                                    Configs{' '}
                                </Link>
                            </li>

                            <li className="nav-item text-nowrap ">
                                <Link to="/occurrences" className="nav-link">
                                    {' '}
                                    Occurrences{' '}
                                </Link>
                            </li>
                        </Nav>
                    </Container>
                </Navbar>
            ) : (
                ''
            )}
        </div>
    )
}
