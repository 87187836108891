import React from 'react'
import './Link.scss'
import history from '../../../routes/history'
import { useLocation } from 'react-router-dom'

export type LinkProps = {
    /**
     * URL to
     */
    to: string
    /**
     * Class name
     */
    className?: string
} & React.ButtonHTMLAttributes<HTMLAnchorElement>

export const Link = ({ to, children, className, ...rest }: LinkProps) => {
    const location = useLocation()
    return (
        <a
            onClick={(e) => {
                e.preventDefault()
                history.push(to)
            }}
            href="#!"
            className={
                className +
                ' ' +
                (to.includes(location.pathname) ? 'active' : '')
            }
        >
            {children}
        </a>
    )
}

Link.defaultProps = {
    to: '/',
}
