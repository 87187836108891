import React from 'react'
import { Header } from '../../components/layout/header/Header'
import { Router, Switch } from 'react-router-dom'
import { routes } from '../../routes/routes.config'
import { Footer } from '../../components/layout/footer/Footer'
import history from '../../routes/history'
import Routes from '../../routes/routes'
import { Row, Container } from 'react-bootstrap'

export default function AppLayout(props) {
    return (
        <div>
            <Header></Header>
            <Container className="container-fluid">
                <Row>
                    <Router history={history}>
                        <Switch>
                            {routes.map((item) => {
                                return (
                                    <Routes
                                        exact
                                        component={item.component}
                                        key={item.path}
                                        path={item.path}
                                    />
                                )
                            })}
                        </Switch>
                    </Router>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
