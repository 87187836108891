import { BehaviorSubject } from 'rxjs'
import { get, post, put } from './http/httpMethods'
import Cookie from 'js-cookie'
import history from '../routes/history'
import { paths } from '../routes/routes.config'
import { showErrorToast } from '../utils/toastUtil'

let currentUserFromStorage: any

/*
 * Get current user from local storage
 */
try {
    currentUserFromStorage = localStorage.getItem('currentUser')
    currentUserFromStorage = JSON.parse(currentUserFromStorage)
    if (currentUserFromStorage) {
        loadCurrentUser()
    }
} catch (e) {
    showErrorToast('Could not find user in local storage')
    logout()
}

const currentUserSubject = new BehaviorSubject(currentUserFromStorage || undefined)
const currentOrganizationSubject = new BehaviorSubject(
    (currentUserFromStorage &&
        currentUserFromStorage._org &&
        currentUserFromStorage._org[0]) ||
    undefined
)

/*
 * Export as a Type
 */
export const authenticationService = {
    logout,
    authToken,
    register,
    verifyCredentials,
    activateUser,
    verifyInvitation,
    acceptInvitation,
    loadCurrentUser,
    requestPasswordReset,
    setPassword,
    isUserAndTokenAvailable,
    verifyOTP,
    handleLogin,
    localLogout,
    resendOTP,
    unsubscribeAll,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    currentOrganization: currentOrganizationSubject.asObservable(),
    get currentOrganizationValue() {
        return currentOrganizationSubject.value
    },
    signInWithSlack,
    verifyBotAdded,
}

/*
 * Verify OTP method
 */
function verifyCredentials(payload: any) {
    return post('/api/auth/login', payload)
        .then((response: any) => {
            handleLogin(response)
            return response
        })
        .catch((error: any) => {
            showErrorToast(
                error.message || 'Error occurred while validating credentials!'
            )
            return error
        })
}

/*
 * Verify OTP method
 */
function requestPasswordReset(payload: any) {
    return post('/api/user/password/reset', payload).then((response: any) => {
        return response
    })
}

/*
 * Unsubscribe all subjects to avoid memory leak
 */
function unsubscribeAll() {
    currentUserSubject.unsubscribe()
    currentOrganizationSubject.unsubscribe()
}

/*
 * Logout method
 */
function logout() {
    return get(`/api/auth/logout`)
        .then((response) => {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser')

            Cookie.remove('_token', { path: '/' })

            currentUserSubject.next({})

            history.push('/auth/login')
            // window.location.reload()
            return response
        })
        .catch((error) => {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser')

            Cookie.remove('_token', { path: '/' })

            currentUserSubject.next({})

            history.push('/auth/login')
        })
}

/*
 * Local logout, don't send API call
 */
function localLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')

    Cookie.remove('_token', { path: '/' })

    currentUserSubject.next({})

    history.push('/auth/login')
    window.location.reload()
}

/*
 * Get auth token from cookie
 */
function authToken() {
    return Cookie.get('_token')
}

/*
 * Register user method
 */
function register(payload: any) {
    return post('/api/user/sign-up', payload).then((response: any) => {
        // handleLogin(response)
        return response
    })
}

/*
 * Accept onboarding invitation
 */
function acceptInvitation(payload: any, token: string) {
    return post('/api/invite-users/accept', payload, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        // handleLogin(response)
        return response
    })
}

/*
 * Set new password
 */
function setPassword(payload: any, token: string) {
    return put('/api/user/password', payload, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        return response
    })
}

/*
 * Verify OTP
 */
function verifyOTP(payload: any) {
    return post('/api/auth/second-factor', payload).then((response: any) => {
        return response
    })
}

/*
 * Verify OTP
 */
function resendOTP() {
    return get('/api/auth/regenerate-second-factor').then((response: any) => {
        handleLogin(response)
        return response
    })
}

/*
 * Activate user
 */
function activateUser(token: string, activate: boolean = false) {
    return get(`/api/user/self${activate ? `?activate=${activate}` : ``}`, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        return response
    })
}

/*
 * Verify invitation
 */
function verifyInvitation(token: string) {
    return get(`/api/invite-users/self`, {
        headers: { Authorization: `${token}` },
    }).then((response: any) => {
        return response
    })
}

/*
 * Verify invitation
 */
function isUserAndTokenAvailable() {
    return authToken() && JSON.parse(localStorage.getItem('currentUser') as any)
}

/*
 * Fetch current user
 */
function loadCurrentUser() {
    get(`/api/auth/self`).then((response: any) => {
        localStorage.setItem('currentUser', JSON.stringify(response))
        currentUserSubject.next(response)
        currentOrganizationSubject.next(response._org[0])
    })
}

/*
 * Register user method
 */
function handleLogin(response: any) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    Cookie.set('_token', response.token, { path: '/' })

    localStorage.setItem('currentUser', JSON.stringify(response.user))

    currentUserSubject.next(response.user)

    currentOrganizationSubject.next(response.user._org[0])
    if (response.user && !response.user._pre) {
        if (response.user.isBotAddedToSlack) {
            history.push(paths.defaultPostLogin)
            window.location.reload()
        } else {
            history.push(paths.addBotToSlack)
        }
    }
}

function signInWithSlack(code: any) {
    const body = { accessCode: code }
    return post('/api/auth/signin/slack', body).then((response: any) => {
        if (response && response.status == true) {
            handleLogin(response)
            return response
        } else {
            showErrorToast(response.message || 'Error occurred while signin with slack.')
            return response
        }

    }).catch((error: any) => {
        showErrorToast(error.message || 'Error occurred while signin with slack.')
        return error
    })
}

/*
 * Verify Bot is added or not
 */
function verifyBotAdded(code: string, token: string) {
    const payload = {code: code}
    return post('/api/auth/slack/verify/bot', payload, {
        headers: { Authorization: `Bearer ${token}` },
    }).then((response: any) => {
        if (response && response.status == true) {
            return response
        } else {
            return Promise.reject(response)
        }
    }).catch((error: any) => {
        return Promise.reject(error)
    })
}
