import { lazy, LazyExoticComponent } from 'react'
// import UserProfile from '../components/user-profile/user-profile'

// Lazy load your page components
const Login = lazy(() => import('../pages/auth/login/login'))
const Register = lazy(() => import('../pages/auth/register/register'))
const ActivateUser = lazy(() => import('../pages/auth/activate-user/activate-user'))
const AcceptInvite = lazy(() => import('../pages/auth/accept-invite/accept-invite'))
const ForgotPassword = lazy(
    () => import('../pages/auth/forgot-password/forgot-password')
)
const SetPassword = lazy(() => import('../pages/auth/set-password/set-password'))
const SecondFactor = lazy(() => import('../pages/auth/second-factor/second-factor'))
const ReportsTable = lazy(() => import('../pages/table/reports-table'))
const CreateReports = lazy(() => import('../pages/report/create-report'))
const EditReports = lazy(() => import('../pages/report/edit-report'))
const OccurencesList = lazy(() => import('../pages/table/occurrences-list'))
const ResponseList = lazy(() => import('../pages/table/response-list'))
const CreateWaterCoolerConfig = lazy(
    () => import('../pages/water-cooler-config/create-water-cooler-config')
)
const EditWaterCoolerConfig = lazy(
    () => import('../pages/water-cooler-config/edit-water-cooler-config')
)
const CreateWaterCooler = lazy(
    () => import('../pages/water-cooler/create-water-cooler')
)
const EditWaterCooler = lazy(() => import('../pages/water-cooler/edit-water-cooler'))
const UserProfile = lazy(() => import('../pages/settings/user-profile/user-profile'))
const AddBotToSlack = lazy(() => import('../pages/auth/add-slack-bot/add-slack-bot'))
const SlackVerification = lazy(
    () => import('../pages/auth/verify-slack-permissions/verify-slack-permissions')
)
const WaterCoolerNavigator = lazy(
    () => import('../pages/table/water-cooler-navigator')
)

/*
 * Route path: URLs
 */
export const paths = {
    defaultPostLogin: '/water-cooler/schedules',
    login: '/auth/login',
    register: '/auth/register',
    activateUser: '/auth/activate-user',
    acceptInvite: '/auth/accept-invite',
    forgotPassword: '/auth/forgot-password',
    setPassword: '/auth/set-password',
    secondFactor: '/auth/second-factor',
    createWaterCoolerConfig: '/water-cooler/schedules/new',
    editWaterCoolerConfig: '/water-cooler/schedules/:id',
    createWaterCooler: '/water-cooler/messages/new',
    editWaterCooler: '/water-cooler/messages/:id',
    createReports: '/reports/new',
    editReports: '/reports/:id',
    reportsTable: '/reports',
    userSettings: '/settings/:settingsTab',
    addBotToSlack: '/auth/add-bot-slack',
    slackVerification: '/auth/slack-verification',
    waterCooler: '/water-cooler/:tab',
    occurencesList: '/occurrences',
    responseList: '/response/:id',
}

/*
 * Components
 */
export const components = {
    Permissions,
}

/*
 * Type defined for path component
 */
interface PathComponent {
    path: string
    component: LazyExoticComponent<(props: any) => JSX.Element>
}

/*
 * Routes: path & lazy loaded component
 */
export const routes: PathComponent[] = [
    {
        path: paths.login,
        component: Login,
    },
    {
        path: paths.register,
        component: Register,
    },
    {
        path: paths.activateUser,
        component: ActivateUser,
    },
    {
        path: paths.acceptInvite,
        component: AcceptInvite,
    },
    {
        path: paths.forgotPassword,
        component: ForgotPassword,
    },
    {
        path: paths.setPassword,
        component: SetPassword,
    },
    {
        path: paths.secondFactor,
        component: SecondFactor,
    },
    {
        path: paths.createWaterCoolerConfig,
        component: CreateWaterCoolerConfig,
    },
    {
        path: paths.editWaterCoolerConfig,
        component: EditWaterCoolerConfig,
    },
    {
        path: paths.createWaterCooler,
        component: CreateWaterCooler,
    },
    {
        path: paths.editWaterCooler,
        component: EditWaterCooler,
    },
    {
        path: paths.reportsTable,
        component: ReportsTable,
    },
    {
        path: paths.createReports,
        component: CreateReports,
    },
    {
        path: paths.editReports,
        component: EditReports,
    },
    {
        path: paths.userSettings,
        component: UserProfile,
    },
    {
        path: paths.addBotToSlack,
        component: AddBotToSlack,
    },
    {
        path: paths.slackVerification,
        component: SlackVerification,
    },
    {
        path: paths.waterCooler,
        component: WaterCoolerNavigator,
    },
    {
        path: paths.occurencesList,
        component: OccurencesList,
    },
    {
        path: paths.responseList,
        component: ResponseList,
    },
]
