import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { routes } from '../../routes/routes.config'
import history from '../../routes/history'
import { Footer } from '../../components/layout/footer/Footer'

export default function AuthLayout() {
    return (
        <>
            <div className="text-center pt-3">
                <h3>99 Bots</h3>
            </div>
            <div className="container">
                <Router history={history}>
                    <Switch>
                        {routes.map((item) => (
                            <Route exact key={item.path} path={item.path}>
                                <item.component />
                            </Route>
                        ))}
                    </Switch>
                </Router>
                <Footer />
            </div>
        </>
    )
}
